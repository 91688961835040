<template>
    <div class="wrap">
        <Play :spaceId="spaceInfo.spaceId" :spaceNm="spaceInfo.spaceNm" :spaceType="spaceInfo.spaceType" :findChannel="'N'"
              v-if="ShowPlay" @closePlay="ShowPlay = false" />
        <div class="space_pc">
            <section class="main space4_4 space4_5">
                <!-- 채널 탐색 중 -->
                <div class="main_box" v-if="lodingStatus && lodingStatus2 === false">
                    <h3>
                        채널을 탐색 중입니다.<br>
                        잠시만 기다려주세요.
                    </h3>
                    <div class="loading_ing">
                        <div class="wrapper">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="shadow"></div>
                            <div class="shadow"></div>
                            <div class="shadow"></div>
                        </div>
                        <!-- <button class="opacity">다음</button> -->
                    </div>
                </div>
                <!-- 새로운 채널 생성 중 -->
                <div class="main_box" v-else-if="lodingStatus2 && lodingStatus === false">
                    <h3>
                        동일한 조건의 채널이 없습니다.<br>
                        새로운 채널을 생성해드릴게요.
                    </h3>
                    <div class="loading_ing">
                        <div class="wrapper">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="shadow"></div>
                            <div class="shadow"></div>
                            <div class="shadow"></div>
                        </div>
                        <button class="opacity">다음</button>
                    </div>
                </div>
                <!-- 로딩 완료 -->
                <div class="main_box" v-else>
                    <h3 v-if="spaceInfo.sector != null">
                        <span>{{ spaceInfo.mood }}한</span> 분위기의<br>
                        <span v-html="spaceInfo.spaceNm" /> 에<br>
                        어울리는 음악 선곡이 완료되었습니다.
                    </h3>
                    <h3 v-else>
                        <span>{{ carCompleteCuration1 }}</span>용 <br>
                        <span>{{ carCompleteCuration2 }}</span>에<br>
                        어울리는 음악 선곡이 완료되었습니다.
                    </h3>
                    <div class="loading_ing">
                        <div class="meet_boll">
                            <div><img src="/media/img/space_4_check.svg" alt=""></div>
                            <img src="/media/img/space_loding_img2.png" alt="">
                        </div>
                        <button @click="linkPlayer()">다음</button>
                    </div>
                </div>
            </section>
        </div>
        <!-- 모바일 채널 탐색 중 -->
        <div class="space_mo h_100" v-if="lodingStatus && lodingStatus2 === false">
            <section class="main space4_4">
                <div class="main_box">
                    <h3>
                        채널을 탐색 중입니다.<br>
                        잠시만 기다려주세요.
                    </h3>
                    <div class="loading_ing">
                        <div class="wrapper">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="shadow"></div>
                            <div class="shadow"></div>
                            <div class="shadow"></div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="form_bottom">
                <!-- <button style="text-align:center;" class="opacity3">다음</button> -->
            </div>
        </div>
        <!-- 모바일 채널 생성 중 -->
        <div class="space_mo h_100" v-else-if="lodingStatus2 && lodingStatus === false">
            <section class="main space4_4">
                <div class="main_box">
                    <h3>
                        동일한 조건의 채널이 없습니다.<br>
                        새로운 채널을 생성해드릴게요.
                    </h3>
                    <div class="loading_ing">
                        <div class="wrapper">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="shadow"></div>
                            <div class="shadow"></div>
                            <div class="shadow"></div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="form_bottom">
                <button style="text-align:center;" class="opacity3">다음</button>
            </div>
        </div>
        <!-- 모바일 로딩 완료 -->
        <div class="space_mo h_100" v-else>
            <section class="main space4_4 space4_5">
                <div class="main_box">
                    <h3 v-if="spaceInfo.sector != null">
                        <span>{{ spaceInfo.mood }}한</span> 분위기의<br>
                        <span v-html="spaceInfo.spaceNm" /> 에<br>
                        어울리는 음악 선곡이 완료되었습니다.
                    </h3>
                    <h3 v-else>
                        <span>{{ carCompleteCuration1 }}</span>용 <br>
                        <span>{{ carCompleteCuration2 }}</span>에<br>
                        어울리는 음악 선곡이 완료되었습니다.
                    </h3>
                    <div class="loading_ing">
                        <div class="meet_boll">
                            <div><img src="/media/img/space_4_check.svg" alt=""></div>
                            <img src="/media/img/space_loding_img2.png" alt="">
                        </div>
                    </div>
                </div>
            </section>
            <div class="form_bottom">
                <button style="text-align:center;" @click="linkPlayer()">다음</button>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import axios from "axios";
import VueCookies from "vue-cookies";
// import router from "@/router";
import Play from "@/components/modal/Space/CurationUpdated.vue";

export default defineComponent({
  name: "FinishedCuration",
  data () {
    return {
      spaceInfo: {},
      lodingStatus: true,
      lodingStatus2: false,
      ShowPlay: false
    };
  },
  components: {
    Play
  },
  methods: {
    linkPlayer () {
      this.ShowPlay = true;
      // router.push({ name: "Player" });
    },
    async getCurationTemp () {
      var spaceId = localStorage.getItem("spaceId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      await axios
        .post("/api/profile/getCurationTemp", { spaceId }, { headers })
        .then((res) => {
          // console.log(res.data.result);
          this.spaceInfo = res.data.result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loding () {
      this.lodingStatus = false;
      this.lodingStatus2 = true;
    },
    loding2 () {
      this.lodingStatus2 = false;
    }
  },
  mounted () {
    this.getCurationTemp();
    setTimeout(this.loding, 2000);
    setTimeout(this.loding2, 4000);
  },
  carCompleteCuration1 () {
    if (Object.keys(this.spaceInfo).length > 0) {
      return this.spaceInfo.carPurpose.length > 1 ? `${this.spaceInfo.carPurpose[0]}+${this.spaceInfo.carPurpose.length - 1}` : this.spaceInfo.carPurpose[0];
    }
    return '';
  },
  carCompleteCuration2 () {
    return `${this.spaceInfo.spaceNm}`;
  }
});
</script>
<style scoped>
/* .space4_4 .loading_ing>div img:nth-child(1) {
    left: 0;
    top: 0;
  } */
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
